import styled from "styled-components"
import Container from "@mui/material/Container"
import FluidBgImg from "../../components/fluid-bg-image/fluid-bg-image"
import Grid from "@mui/material/Grid"

export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "white")};
`
export const CustomGrid = styled(Grid)``
export const NumberContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  order: 1;

  &.leftAlign {
    justify-content: flex-end;
    order: 2;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      order: 1;
      justify-content: flex-start;
    }
  }
`
export const ImageContainer = styled(Grid)`
  order: 2;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  &.leftAlign {
    justify-content: flex-start;
    order: 1;
  }

  img {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    ${({ overlapImg }) =>
      overlapImg
        ? `
      
      img { 
        position: absolute !important;
         width: 145% !important;
             top: -40%;
              left: -100px;
              max-width: unset
       }
  `
        : ``};
  }
`
export const HeroSection = styled(FluidBgImg)`
  min-height: 583px;
  display: flex;
  background-color: ${({ theme }) => theme.palette.primary.main};
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`
export const CustomContainer = styled(Container)`
  padding-top: 6em;
  padding-bottom: 6em;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-top: 0;
    padding-bottom: 0;
  }
`
