import * as S from "./video-section.styles"
import React from "react"

const VideoSection = () => {
  return (
    <S.Wrapper contained bg="light">
      <S.Title>Learn about the Radius Book Group experience</S.Title>
      <video
        controls={true}
        autoPlay={true}
        poster="https://admin.radiusbookgroup.com/wp-content/uploads/2022/02/video-placeholder.png"
      >
        <source
          src="https://admin.radiusbookgroup.com/wp-content/uploads/2021/02/RADIUS-BOOK-GROUP-2-MINUTE-EDIT-02-08-21-1.mp4"
          type="video/mp4"
        />
      </video>
    </S.Wrapper>
  )
}
export default VideoSection
