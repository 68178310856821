import styled from "styled-components"
import { Typography } from "@mui/material"
import FluidImage from "../fluid-image/fluid-image"
import CustomLink from "../custom-link/custom-link.component"

export const CardContainer = styled(CustomLink)`
  text-decoration: none;
  padding: 0 1em;
  display: block;
  //cursor: pointer;
  //margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  align-items: flex-start;
`
export const AuthorContainer = styled.div`
  padding-bottom: 3em;
`
export const AuthorImage = styled(FluidImage)`
  height: 280px;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    height: 300px;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 430px;
  }
  width: 100%;
  margin-bottom: 1em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`
export const AuthorImageContainer = styled.div`
  width: 35px;
  height: 35px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`

// export const AuthorImage = styled.img`
//   display: inline;
//   margin: 0 auto;
//   margin-left: 20%; //centers the image
//   height: 100%;
//   width: auto;
// `

export const AuthorName = styled(Typography)`
  color: #0f1418;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 20px;
  padding-bottom: 0.5em;
`

export const Role = styled(Typography)`
  opacity: 0.5;
  color: #0f1418;
  font-size: 14px;
  letter-spacing: 0.88px;
  line-height: 16px;
`
