import styled from "styled-components"
import CustomTypography from "../../components/custom-typography/custom-typography.component"
import Grid from "@mui/material/Grid"
import Calendar from "../../assets/icons/calendar.svg"

export const CustomGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up("sm")} {
    ${({ flipCols }) =>
      flipCols
        ? `flex-direction: row-reverse;
          .title {
            text-align: right;
          }
          `
        : ``}
  }
`
export const Title = styled(CustomTypography)`
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-weight: bold;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 2rem;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    text-align: left;
  }
`
export const Item = styled.div`
  margin-bottom: 2.5em;
`
export const Content = styled(CustomTypography)`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  opacity: 0.5;
  max-width: 100%;
  &.news {
    display: -webkit-box;
    max-height: 120px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    a,
    p,
    i,
    b,
    span {
      overflow: hidden;
    }
    p {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
`

export const CalendarIcon = styled(Calendar)`
  width: 24px;
  height: 24px;
  margin-left: 1rem;
`
