import React, { useRef } from "react"
import * as S from "./custom-slider.styles.jsx"
import ArrowBackIosIcon from "../../assets/icons/arrow-right.svg"
import ArrowForwardIosIcon from "../../assets/icons/arrow-left.svg"
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"

const CustomSlider = ({
  children,
  slidesToShow,
  breakpoints,
  style,
  infinite = true,
  autoplay = false,
  autoplaySpeed,
  speed = 500,
}) => {
  let slider = useRef(null)
  const settings = {
    dots: false,
    infinite,
    autoplay,
    autoplaySpeed,
    speed,
    slidesToShow,
    slidesToScroll: 1,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    responsive: breakpoints,
  }

  return (
    <S.CustomSlider {...settings} ref={c => (slider = c)} style={style}>
      {children}
    </S.CustomSlider>
  )
}
export default CustomSlider
