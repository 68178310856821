import styled from "styled-components"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Slider from "react-slick"
import CustomTypography from "../custom-typography/custom-typography.component"

export const Content = styled(Container)`
  padding: 2em 0;
`
export const CustomContainer = styled(Grid)`
  padding-top: 2em;
  p,
  a {
    margin-bottom: 1em;
  }
  a {
    margin-top: 2em;
  }
  justify-content: space-around;
  max-width: 384px;
  order: 1;
  &.left {
    order: 2;
    ${({ theme }) => theme.breakpoints.down("sm")} {
      order: 1;
    }
  }
  .author-image {
    padding-bottom: 1em;
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    .author-image {
      display: none;
    }
  }
`
export const ButtonContainer = styled.div`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    text-align: center;
  }
`
export const CustomSlider = styled(Slider)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-bottom: 2em;
  }

  svg {
    width: 16px;
    height: 29px;
  }
`
export const Tag = styled.h3`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.text.tertiary};
  letter-spacing: 3.33px;
`
export const ContentText = styled.div`
  font-size: 0.875rem;
  letter-spacing: 0.88px;
  & :not(.light) {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  h3,
  p {
    opacity: 0.5;
  }
  a {
    color: inherit;
    opacity: 1;
  }
`
export const AuthorName = styled.h2`
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  letter-spacing: 2.13px;
  padding-bottom: 1em;
`
export const ImageContainer = styled(Grid)`
  cursor: pointer;
  max-width: 364px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
  order: 2;
  &.left {
    order: 1;
  }
`
export const CustomTag = styled.h3`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.text.tertiary};
  letter-spacing: 3.33px;
  padding-bottom: 1em;
`
export const GridContainer = styled(Grid)`
  align-items: center;
  justify-content: space-around;
  ${({ theme }) => theme.breakpoints.up("md")} {
    ${({ flipCols }) =>
      flipCols
        ? `
  .MuiGrid-item {
      &:last-child {
        order: 1;
    }
    
     &:first-child {
        order: 2;
     }
 `
        : ""};
  }
`
