import * as S from "../AboutBanner/about-banner.styles"
import React from "react"
import Grid from "@mui/material/Grid"
import parse from "html-react-parser"

const AboutBanner = ({ backgroundImage, title, description }) => {
  if (!backgroundImage) return null
  return (
    <S.Wrapper>
      <S.HeroSection tag="div" img={backgroundImage}>
        <div />
      </S.HeroSection>
      <S.CustomContainer maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <S.TitleContainer>
              <S.Title>{title}</S.Title>
            </S.TitleContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <S.Description>{parse(description)}</S.Description>
          </Grid>
        </Grid>
      </S.CustomContainer>
    </S.Wrapper>
  )
}
export default AboutBanner
