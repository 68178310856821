import React from "react"
import * as S from "./title-content.styles"

const TitleContent = ({ title, description, className }) => {
  return (
    <S.CustomContainer>
      <S.TextWrapper>
        <S.Title fontSize={title.size}>{title.text}</S.Title>
        <S.Description className={className} fontSize={description.size}>
          {description.text}
        </S.Description>
      </S.TextWrapper>
    </S.CustomContainer>
  )
}
export default TitleContent
