import React, { useRef } from "react"
import * as S from "./featured-success-slider.styles"
import ArrowBack from "../../assets/icons/arrow-right-white.svg"
import ArrowForward from "../../assets/icons/arrow-left-white.svg"
import FeaturedSuccessStory from "../../components/featured-success-story/featured-success-story.component"

const FeaturedSuccessSlider = ({ slides }) => {
  let slider = useRef(null)
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    arrows: slides.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowForward />,
    prevArrow: <ArrowBack />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          dots: true,
          arrows: false,
          adaptiveHeight: true,
        },
      },
    ],
  }
  if (!slides) return null
  return (
    <S.Wrapper className="dark" contained>
      <S.CustomSlider {...settings} ref={c => (slider = c)}>
        {slides.map(slide => (
          <FeaturedSuccessStory
            tag={slide.tagText}
            contentText={slide.content}
            authorName={slide.title}
            button={
              slide.button
                ? {
                    uri: slide.button && slide.button.url,
                    text: slide.button && slide.button.title,
                    className:
                      slide.button && slide.button.className
                        ? slide.button.className
                        : "lightBorder",
                  }
                : null
            }
            flipCols=""
            img={slide.authorImage}
          />
        ))}
      </S.CustomSlider>
    </S.Wrapper>
  )
}
export default FeaturedSuccessSlider
