import React from "react"
import MiscContent from "./layouts/MiscContent"
import HeroSlider from "./layouts/HeroSlider"
import TitleColumn from "./layouts/TitleColumn"
import FeaturedSuccessSlider from "./layouts/FeaturedSuccessSlider"
import AuthorSlider from "./layouts/AuthorSlider"
import AuthorSaySlider from "./layouts/AuthorSaySlider"
import ReleasesSlider from "./layouts/ReleasesSlider"
import BigNumberSection from "./layouts/BigNumberSection"
import RadiusAuthors from "./layouts/RadiusAuthors"
import PartnersSlider from "./layouts/PartnersSlider"
import ImageTextSection from "./layouts/ImageTextSection"
import HeroBanner from "./layouts/HeroBanner"
import AllBooks from "./layouts/AllBooks"
import AllAuthors from "./layouts/AllAuthors"
import AboutBanner from "./layouts/AboutBanner"
import OurTeam from "./layouts/OurTeam"
import AllSuccessStories from "./layouts/AllSuccessStories"
import AboutWhy from "./layouts/AboutWhy"
import AllNews from "./layouts/AllNews"
import VideoSection from "./layouts/VideoSection"
import RadiusPublishesSlider from "./layouts/RadiusPublishesSlider"

const getLayout = layout => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContent":
      return <MiscContent {...layout} />
    case "HeroSlider":
      return <HeroSlider {...layout} />
    case "TitleColumn":
      return <TitleColumn {...layout} />
    case "FeaturedSuccessSlider":
      return <FeaturedSuccessSlider {...layout} />
    case "AuthorSlider":
      return <AuthorSlider {...layout} />
    case "AuthorSaySlider":
      return <AuthorSaySlider {...layout} />
    case "ReleasesSlider":
      return <ReleasesSlider {...layout} />
    case "BigNumberSection":
      return <BigNumberSection {...layout} />
    case "RadiusAuthors":
      return <RadiusAuthors {...layout} />
    case "PartnersSlider":
      return <PartnersSlider {...layout} />
    case "ImageTextSection":
      return <ImageTextSection {...layout} />
    case "HeroBanner":
      return <HeroBanner {...layout} />
    case "AllBooks":
      return <AllBooks {...layout} />
    case "AllAuthors":
      return <AllAuthors {...layout} />
    case "AboutBanner":
      return <AboutBanner {...layout} />
    case "OurTeam":
      return <OurTeam {...layout} />
    case "AllSuccessStories":
      return <AllSuccessStories {...layout} />
    case "AboutWhy":
      return <AboutWhy {...layout} />
    case "AllNews":
      return <AllNews {...layout} />
    case "VideoSection":
      return <VideoSection {...layout} />
    case "RadiusPublishesSlider":
      return <RadiusPublishesSlider {...layout} />
  }
}

export default getLayout
