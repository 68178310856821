import styled from "styled-components"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import CustomTypography from "../../components/custom-typography/custom-typography.component"
import Slider from "react-slick"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import FluidBgImg from "../../components/fluid-bg-image/fluid-bg-image"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
  position: relative;

  p,
  h2,
  h3,
  a,
  li,
  ul {
    position: relative;
    z-index: 2;
  }

  /*  &:before {
    content: "";
    position: absolute;
    display: block;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }*/

  // ${({ theme }) => theme.breakpoints.up("md")} {
  //   &:before {
  //     height: 80%;
  //   }
  // }
`
export const ImageContainer = styled(FluidBgImg)`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 500px;
  padding-bottom: 2em;

  .MuiGrid-container {
    max-width: 300px;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    :before,
    :after {
      background-position: 0 -45px !important;
    }

    .MuiGrid-container {
      max-width: 430px;
    }
  }

  //max-height: 644px;
  //position: relative;
  //max-width: 606px;
  //z-index: 2;
  //margin: 0 auto;
  //height: 100%;
  //width: auto;
  //overflow: hidden;
  // ${({ theme }) => theme.breakpoints.only("sm")} {
  //   div:first-child {
  //     position: unset !important;
  //   }
  // }
  // ${({ theme }) => theme.breakpoints.up("sm")} {
  //   .gatsby-image-wrapper {
  //     position: absolute !important;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     img{
  //       object-position: top;
  //     }
  //   }
  // }
`
export const Mask = styled.div`
  position: absolute;
  display: block;
  right: 0;
  left: 0;
  bottom: 0;
  height: 230px;
  width: 100%;
  z-index: 1;
  background: linear-gradient(180deg, rgba(15, 20, 24, 0) 0%, #0f1418 100%);
`
export const GridContainer = styled(Grid)`
  margin: 0 auto;
`
export const BookImage = styled(Grid)`
  max-width: 130px;
  z-index: 2;
  height: 100%;
  position: relative;

  .gatsby-image-wrapper {
    height: 100%;
    max-width: 96px;
    position: relative !important;
    top: unset !important;
    transform: unset !important;
    //img{
    //  object-fit: contain!important;
    //}
  }
`
export const BookContent = styled(Grid)`
  color: ${({ theme }) => theme.palette.text.secondary};

  h2 {
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    font-size: ${({ theme }) => theme.typography.pxToRem(20)};
    font-weight: bold;
    letter-spacing: 1.25px;
    line-height: 26px;
  }

  p {
    letter-spacing: 0.88px;
    line-height: 26px;
  }

  .subtitle {
    opacity: 0.5;
    font-size: 1rem;
    line-height: 18px;
    max-width: 190px;
  }
`
export const ItemContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: 6em;
    padding-bottom: 8em;
  }

  .MuiListItem-gutters {
    padding-left: 0;
    padding-right: 0;
  }
`

export const CustomCheck = styled(ListItemIcon)`
  color: ${({ theme }) => theme.palette.secondary.main};
`
export const ContentText = styled(ListItemText)`
  color: ${({ theme }) => theme.palette.text.secondary};
  opacity: 0.5;
`
export const Title = styled(CustomTypography)`
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: bold;
  line-height: 70px;
  font-size: 3rem;
  margin-bottom: 1em;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 4rem;
  }
`
export const CustomSlider = styled(Slider)`
  padding-bottom: 3em;
`
