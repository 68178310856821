import styled from "styled-components"
import { Typography } from "@mui/material"
import FluidImage from "../fluid-image/fluid-image"
import CustomLink from "../custom-link/custom-link.component"

export const Card = styled.div`
  display: block;
  padding-bottom: 2.5em;
  border-bottom: 1px solid rgba(62, 62, 62, 0.2);
  margin-bottom: 2.5em;
`

export const CustomImage = styled(FluidImage)`
  height: 231px;
  width: auto;
  img {
    object-fit: contain !important;
    object-position: top left;
  }
`

export const Title = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-weight: 600;
  line-height: 140%;
  padding-bottom: 1em;
`

export const Date = styled(Typography)`
  color: #999999;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  letter-spacing: 1px;
  line-height: 19px;
  padding-bottom: 1.5rem;
`

export const Description = styled.div`
  color: #444444;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: 149%;
  margin-bottom: 1.5rem;
  overflow: hidden;
  max-height: 80px;
  p {
    margin: 0;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  a,
  p,
  i,
  b,
  span,
  h1,
  h2,
  h3,
  h3 {
    overflow: hidden;
  }
`
export const Content = styled.div`
  max-width: 585px;
`
export const Link = styled(CustomLink)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
`
export const ImageLink = styled(CustomLink)`
  display: block;
`
