import React from "react"
import * as S from "./our-team.styles.jsx"
import TeamCard from "../../components/team-card/team-card.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

const OurTeam = ({ title, members }) => {
  return (
    <SectionWrapper contained maxWidth="md" bg="main">
      <S.Title>{title}</S.Title>
      <S.AuthorGrid container spacing={5}>
        {members.map(member => (
          <S.Item item sm={6} md={4} xs={12}>
            <TeamCard member={member} />
          </S.Item>
        ))}
      </S.AuthorGrid>
    </SectionWrapper>
  )
}
export default OurTeam
