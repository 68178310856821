import styled from "styled-components"

export const SocialContainer = styled.div`
  background-color: #f2f0ec;
  padding: 2em 0;
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: 140%;
  font-weight: 600;
  text-align: center;
  svg {
    height: 40px;
  }
  .social {
    padding-bottom: 0;
    padding-top: 1.5em;
  }
`
