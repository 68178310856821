import React from "react"
import * as S from "./team-card.styles"

const TeamCard = ({ member }) => {
  return (
    <S.CardContainer>
      {member.name && (
        <S.AuthorContainer>
          {member.image && (
            <S.AuthorImageContainer>
              <S.AuthorImage img={member.image} />
            </S.AuthorImageContainer>
          )}
          <S.AuthorName>{member.name}</S.AuthorName>
          <S.Role>{member.role}</S.Role>
          <S.Description>{member.description}</S.Description>
        </S.AuthorContainer>
      )}
    </S.CardContainer>
  )
}

export default TeamCard
