import styled from "styled-components"
import CustomTypography from "../custom-typography/custom-typography.component"

export const CustomContainer = styled(CustomTypography)`
  color: ${({ theme }) => theme.palette.text.tertiary};
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  letter-spacing: 12.5px;
`
export const Title = styled(CustomTypography)`
  color: ${({ theme }) => theme.palette.text.tertiary};
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  letter-spacing: 12.5px;
`

export const Description = styled(CustomTypography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  letter-spacing: 1.75px;
  text-align: left;
  padding-left: 0.5em;
  &.darkFont {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`
export const TextWrapper = styled.div`
  max-width: 333px;
`
