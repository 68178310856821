import React, { useEffect, useRef, useState } from "react"
import * as S from "./author-say-slider.styles.jsx"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { textEllipsis } from "../../utils"
import FluidImage from "../../components/fluid-image/fluid-image"
import { ArrowBack, ArrowForward } from "@mui/icons-material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { createTheme } from "@mui/material/styles"
import { getSrc } from "gatsby-plugin-image"
import {
  AbsoluteContainer,
  CustomContainer,
  QuotesIcon,
} from "./author-say-slider.styles.jsx"

const AuthorSaySlider = ({ authors }) => {
  const totalSliders = authors?.length
  const defaultTheme = createTheme()
  const matchesMd = useMediaQuery(defaultTheme.breakpoints.up("md"))
  const parse = require("html-react-parser")
  const [navs, setNavs] = useState({ nav1: null, nav2: null })
  let slider1 = useRef()
  let slider2 = useRef()
  useEffect(() => {
    setNavs({
      nav1: slider1.current,
      nav2: slider2.current,
    })
  }, [])

  useEffect(() => {
    const rand = Math.floor(Math.random() * totalSliders)

    if (slider1.current && slider2.current) {
      slider1.current.slickGoTo(rand)
      slider2.current.slickGoTo(rand)
    }
  }, [slider1.current, slider2.current])

  const staticImages = useStaticQuery(
    graphql`
      query {
        quotation: file(relativePath: { eq: "quotation.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, quality: 100)
          }
        }
      }
    `
  )
  const settingsSlider1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    fade: true,
    adaptiveHeight: true,
    slidesToScroll: 1,
    swipe: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 960,
        settings: {
          adaptiveHeight: true,
          swipe: true,
          arrows: false,
          dots: true,
          fade: false,
        },
      },
    ],
  }

  const settingsSlider2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <ArrowForward />,
    prevArrow: <ArrowBack />,
  }

  return (
    <S.Wrapper contained position="relative">
      <S.FirstSlider
        {...settingsSlider1}
        asNavFor={navs.nav2}
        ref={c => (slider1.current = c)}
      >
        {authors.map(author => (
          <Container onClick={() => navigate(author.book.uri)}>
            <S.Content container>
              <Grid item xs={12} sm={6} md={7}>
                <S.CustomContainer>
                  <S.Text fontSize={3.1} ls={3.125}>
                    What Our Authors Say
                  </S.Text>
                  <S.QuotesIcon />
                  <S.QuoteText fontSize={1.375} ls={1.375}>
                    {textEllipsis(author.quoteText, 240)}
                  </S.QuoteText>
                  <S.AuthorName>{author.authorName}</S.AuthorName>
                  <S.Description>
                    {author.bookText ? parse(author.bookText) : ""}
                  </S.Description>
                </S.CustomContainer>
              </Grid>
              <Grid item xs={12} sm={6} md={5} style={{ margin: "auto" }}>
                <Container>
                  <FluidImage img={author.rightImage} />
                </Container>
              </Grid>
            </S.Content>
          </Container>
        ))}
      </S.FirstSlider>
      {matchesMd && (
        <S.AbsoluteContainer>
          <S.SliderGrid container>
            <S.GridItem item xs={12} md={7}>
              <S.CustomSlider
                {...settingsSlider2}
                ref={c => (slider2.current = c)}
                asNavFor={navs.nav1}
              >
                {authors.map(({ rightImage }) => (
                  <S.Content>
                    <S.AuthorImage
                      img={getSrc(
                        rightImage.localFile.childImageSharp.gatsbyImageData
                      )}
                      alt={rightImage.altText ?? rightImage.title}
                    />
                  </S.Content>
                ))}
              </S.CustomSlider>
            </S.GridItem>
          </S.SliderGrid>
        </S.AbsoluteContainer>
      )}
    </S.Wrapper>
  )
}
export default AuthorSaySlider
