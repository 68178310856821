import styled from "styled-components"
import CustomTypography from "../../components/custom-typography/custom-typography.component"

export const ContentText = styled(CustomTypography)`
  opacity: 0.5;
`
export const Title = styled(CustomTypography)`
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-weight: bold;
`
export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3.5em;
`
