import styled from "styled-components"
import CustomTypography from "../../components/custom-typography/custom-typography.component"
import FluidImage from "../../components/fluid-image/fluid-image"

export const Wrapper = styled.section`
  background-color: #f2f0ec;
`
export const Title = styled(CustomTypography)`
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-weight: bold;
`
export const CustomImage = styled(FluidImage)`
  width: 100%;
  height: auto;
`
export const TopContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3.5em;
  a {
    margin-top: 2em;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    a {
      margin: 0;
    }
  }
`
