import styled from "styled-components"
import Slider from "react-slick"
import Container from "@mui/material/Container"
import ArrowForward from "../../assets/icons/arrow-left-white.svg"
import ArrowBack from "../../assets/icons/arrow-right-white.svg"

export const Wrapper = styled(Container)`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: ${({ maxHeight }) => (`${maxHeight}px` ? maxHeight : "400px")};
`
export const CustomSlider = styled(Slider)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-bottom: 2em;
  }
  svg {
    color: black;
    width: 16px;
    height: 29px;
  }
  @media (min-width: 1410px) {
    .slick-next {
      right: -70px;
    }
    .slick-prev {
      left: -70px;
    }
  }
  .slick-dots {
    position: relative;
    li {
      button {
        border: 1px solid gray;
      }
      &.slick-active {
        button {
          background-color: ${({ theme }) => theme.palette.secondary.main};
          border: 1px solid ${({ theme }) => theme.palette.secondary.main};
        }
      }
    }
  }
`
export const CustomArrowForward = styled(ArrowForward)`
  width: 16px;
  height: 29px;
`
export const CustomArrowBack = styled(ArrowBack)`
  width: 16px;
  height: 29px;
`
