import { Grid, useTheme } from "@mui/material"
import React, { useRef, useState } from "react"
import * as S from "./hero-slider.styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import "react-modal-video/css/modal-video.min.css"
import { PlayArrow } from "@mui/icons-material"

const HeroSlider = ({ slides }) => {
  const [isOpen, setIsOpen] = useState(false)
  require("html-react-parser")
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("lg"))
  const [count, setCount] = useState(0)
  let slider = useRef()
  const videoRef = useRef(null)
  const timeouts = [4000, 12000]
  const next = () => {
    if (slider) {
      slider.slickNext()
    } else {
      setCount(count + 1)
    }
  }

  const previous = () => {
    slider.slickPrev()
  }
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    arrows: false,
    afterChange: current => {
      handleAutoPlay(timeouts[current])
    },
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          adaptiveHeight: true,
          dots: true,
        },
      },
    ],
  }

  const handleAutoPlay = time => {
    const timer = setTimeout(() => {
      next()
    }, time)
    return () => clearTimeout(timer)
  }
  // useEffect(() => {
  //   handleAutoPlay(2000)
  // }, [count])
  if (!slides) return null

  return (
    <>
      <S.ModalVideo
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth="md"
        PaperProps={{ square: true }}
        onRendered={() =>
          videoRef && videoRef.current ? videoRef.current.play() : ""
        }
      >
        <video controls={true} autoPlay={true} ref={videoRef}>
          <source
            src="https://admin.radiusbookgroup.com/wp-content/uploads/2021/02/RADIUS-BOOK-GROUP-2-MINUTE-EDIT-02-08-21-1.mp4"
            type="video/mp4"
          />
        </video>
      </S.ModalVideo>

      <S.Wrapper>
        {/*{matches && slides.length > 1 && (*/}
        {/*<S.Arrows>*/}
        {/*  <S.ArrowButton className="left" onClick={previous}>*/}
        {/*    <ArrowBack />*/}
        {/*  </S.ArrowButton>*/}
        {/*  <S.ArrowButton className="right" onClick={next}>*/}
        {/*    <ArrowForward />*/}
        {/*  </S.ArrowButton>*/}
        {/*</S.Arrows>*/}
        {/*)}*/}
        {/*<Slider {...settings} ref={c => (slider = c)}>*/}
        {/*  {slides.map((slide, index) => (*/}
        {/*    <S.SlideWrapper*/}
        {/*      tag="div"*/}
        {/*      key={`slide-hero-${index}`}*/}
        {/*      img={slide.backgroundImage}*/}
        {/*    >*/}
        {/*      <S.ContentWrapper>*/}
        {/*        <Grid container spacing={3}>*/}
        {/*          <Grid item xs={12} md={7}>*/}
        {/*            <S.MainText>{slide.title}</S.MainText>*/}
        {/*          </Grid>*/}
        {/*<Grid item xs={12} md={5}>*/}
        {/*  <S.BookHeader>*/}
        {/*    <S.AuthorName>*/}
        {/*      {slide.selectBook.booksFields.authorRepeater.length > 0*/}
        {/*        ? slide.selectBook.booksFields.authorRepeater[0].author*/}
        {/*            .title*/}
        {/*        : ""}*/}
        {/*    </S.AuthorName>*/}
        {/*    <S.AuthorLink>*/}
        {/*      Author's Media <ArrowForwardIosIcon />*/}
        {/*    </S.AuthorLink>*/}
        {/*  </S.BookHeader>*/}

        {/*  <S.BookContentWrapper>*/}
        {/*    <S.BookCover img={slide.selectBook.featuredImage.node} />*/}
        {/*    <S.MediaWrapper>*/}
        {/*      <TheatersIcon />*/}
        {/*      <div>*/}
        {/*        <CustomTypography colorInherit>*/}
        {/*          Media Clip/Interview*/}
        {/*        </CustomTypography>*/}
        {/*        <S.AuthorLink>*/}
        {/*          Learn more*/}
        {/*          <ArrowForwardIosIcon />*/}
        {/*        </S.AuthorLink>*/}
        {/*      </div>*/}
        {/*    </S.MediaWrapper>*/}
        {/*  </S.BookContentWrapper>*/}
        {/*</Grid>*/}
        {/*      </Grid>*/}
        {/*    </S.ContentWrapper>*/}
        {/*  </S.SlideWrapper>*/}
        {/*))}*/}
        <S.VideoWrapper>
          <S.ContentWrapper className="video">
            <S.InnerWrapper>
              <S.MainText>
                Radius Publishes with Passion and Purpose.
              </S.MainText>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={12}>
                  <S.SmallText>Learn about the Radius Experience</S.SmallText>
                </Grid>
                <Grid item xs={2} sm={12}>
                  <S.Circle onClick={() => setIsOpen(true)}>
                    <PlayArrow />
                  </S.Circle>
                </Grid>
              </Grid>
            </S.InnerWrapper>
            <S.VideoBg
              autoPlay
              muted
              loop
              preload="metadata"
              playsInline
              src="radius-short-authorstills.mp4#t=0.2"
            />
          </S.ContentWrapper>
        </S.VideoWrapper>
        {/*  </Slider>*/}
      </S.Wrapper>
    </>
  )
}

export default HeroSlider
