import styled from "styled-components"
import Slider from "react-slick"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-bottom: 0;
    padding-top: 0;
  }
  .slick-dots {
    position: relative;
  }
  background-color: ${({ theme }) => theme.palette.primary.light};
  &.light {
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    h2,
    p {
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`
export const CustomSlider = styled(Slider)`
  @media (max-width: 1024px) {
    padding-bottom: 4em;
  }
  svg {
    width: 16px;
    height: 29px;
  }
  @media (max-width: 1330px) {
    .slick-next {
      right: -5px;
    }
    .slick-prev {
      left: -5px;
    }
  }
  @media (min-width: 1410px) {
    .slick-next {
      right: -70px;
    }
    .slick-prev {
      left: -70px;
    }
  }
`
