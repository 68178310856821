import React from "react"
import * as S from "./featured-success-story.styles.jsx"
import { Box, useTheme } from "@mui/material"
import { textEllipsis } from "../../utils"
import Hidden from "@mui/material/Hidden"
import FluidImage from "../fluid-image/fluid-image"
import CustomButton from "../custom-button/custom-button.component"
import { navigate } from "gatsby"

const FeaturedSuccessStory = ({
  tag,
  authorName,
  img,
  contentText,
  button,
  flipCols,
  className,
}) => {
  const theme = useTheme()
  const parse = require("html-react-parser")
  return (
    <S.GridContainer flipCols={flipCols} container spacing={1}>
      <S.CustomContainer item xs={12} sm={6}>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(button.uri)}>
          <S.Tag>{tag}</S.Tag>
          <S.AuthorName
            fontSize={2}
            color={theme.palette.text.secondary}
            ls={2.13}
          >
            {authorName}
          </S.AuthorName>
          <FluidImage img={img} className="author-image" />
        </div>
        <S.ContentText className={className}>
          {contentText ? parse(textEllipsis(contentText, 300)) : ""}
        </S.ContentText>
        {button && (
          <S.ButtonContainer>
            <CustomButton href={button.uri} className={button.className}>
              {button.text}
            </CustomButton>
          </S.ButtonContainer>
        )}
      </S.CustomContainer>
      <S.ImageContainer
        item
        xs={12}
        sm={6}
        className="left"
        onClick={() => navigate(button.uri)}
      >
        <FluidImage img={img} />
      </S.ImageContainer>
    </S.GridContainer>
  )
}

export default FeaturedSuccessStory
