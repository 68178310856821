import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import getLayout from "../../get-layout-util"

export const query = graphql`
  query PageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      isFrontPage
      title
      content
      uri
      slug
      parentId
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      pageBuilder {
        layouts {
          __typename
          ... on WpPage_Pagebuilder_Layouts_MiscContent {
            ...MiscContent
          }
          ... on WpPage_Pagebuilder_Layouts_HeroSlider {
            ...HeroSlider
          }
          ... on WpPage_Pagebuilder_Layouts_TitleColumn {
            ...TitleColumn
          }
          ... on WpPage_Pagebuilder_Layouts_FeaturedSuccessSlider {
            ...FeaturedSuccessSlider
          }
          ... on WpPage_Pagebuilder_Layouts_AuthorSlider {
            ...AuthorSlider
          }
          ... on WpPage_Pagebuilder_Layouts_AuthorSaySlider {
            ...AuthorSaySlider
          }
          ... on WpPage_Pagebuilder_Layouts_ReleasesSlider {
            ...ReleasesSlider
          }
          ... on WpPage_Pagebuilder_Layouts_BigNumberSection {
            ...BigNumberSection
          }
          ... on WpPage_Pagebuilder_Layouts_RadiusAuthors {
            ...RadiusAuthors
          }
          #          ... on WpPage_Pagebuilder_Layouts_CaseStudiesGrid {
          #            ...CaseStudyTestimonial
          #          }
          ... on WpPage_Pagebuilder_Layouts_PartnersSlider {
            ...PartnersSlider
          }
          ... on WpPage_Pagebuilder_Layouts_ImageTextSection {
            ...ImageTextSection
          }
          ... on WpPage_Pagebuilder_Layouts_HeroBanner {
            ...HeroBanner
          }
          #          ... on WpPage_Pagebuilder_Layouts_HeroBanner {
          #            ...HeroBanner
          #          }
          ... on WpPage_Pagebuilder_Layouts_AllBooks {
            ...AllBooks
          }
          ... on WpPage_Pagebuilder_Layouts_AllAuthors {
            ...AllAuthors
          }
          ... on WpPage_Pagebuilder_Layouts_AboutBanner {
            ...AboutBanner
          }
          ... on WpPage_Pagebuilder_Layouts_OurTeam {
            ...OurTeam
          }
          ... on WpPage_Pagebuilder_Layouts_AllSuccessStories {
            ...AllSuccessStories
          }
          ... on WpPage_Pagebuilder_Layouts_AboutWhy {
            ...AboutWhy
          }
          ... on WpPage_Pagebuilder_Layouts_AllNews {
            ...AllNews
          }
          ... on WpPage_Pagebuilder_Layouts_VideoSection {
            ...VideoSection
          }
          ... on WpPage_Pagebuilder_Layouts_RadiusPublishesSlider {
            ...RadiusPublishesSlider
          }
        }
        pageConfiguration {
          hideFooter
          hideHeaderItems
        }
      }
    }
  }
`
const PageTemplate = ({ data }) => {
  const { seo, pageConfiguration, slug, pageBuilder } = data.wpPage
  const layouts = pageBuilder.layouts || []
  const canonical = `${process.env.GATSBY_WEBSITE_URL}${seo.canonical}`

  return (
    <Layout {...pageConfiguration} seo={{ ...seo, canonical }} slug={slug}>
      {layouts.map(layout => getLayout(layout))}
    </Layout>
  )
}

export default PageTemplate
