import React from "react"
import * as S from "./partners-silder.styles"
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { getSrc } from "gatsby-plugin-image"

const PartnersSlider = ({ partners, title }) => {
  require("html-react-parser")
  const linkedLogo = (url, image, alt) => {
    return (
      <S.ImageLink href={url} target="_blank" rel="noreferrer">
        <S.PartnersImg img={image} alt={alt} />
      </S.ImageLink>
    )
  }
  const logo = (image, alt) => {
    return (
      <S.ItemWrapper>
        <S.PartnersImg img={image} alt={alt} />
      </S.ItemWrapper>
    )
  }
  if (!partners) return null
  return (
    <SectionWrapper bg="gray">
      <S.PartnersContainer>
        <S.PartnerTitle fontSize={1.2} ls={1.25}>
          {title} as
        </S.PartnerTitle>
        <S.PartnersSlider
          style={{ margin: "0 -1em" }}
          slidesToShow={4}
          autoplay={true}
          speed={500}
          autoplaySpeed={5000}
          breakpoints={[
            {
              breakpoint: 1330,
              settings: {
                arrows: false,
                dots: true,
              },
            },
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                rows: 2,
                slidesToShow: 2,
                dots: true,
              },
            },
          ]}
        >
          {partners.map(({ partner, url }) =>
            url
              ? linkedLogo(
                  url,
                  getSrc(partner.localFile.childImageSharp.gatsbyImageData),
                  partner.altText ? partner.altText : partner.title
                )
              : logo(
                  getSrc(partner.localFile.childImageSharp.gatsbyImageData),
                  partner.altText ? partner.altText : partner.title
                )
          )}
        </S.PartnersSlider>
      </S.PartnersContainer>
    </SectionWrapper>
  )
}

export default PartnersSlider
