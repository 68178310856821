import React from "react"
import * as S from "./big-number-section.styles"
import FluidImage from "../../components/fluid-image/fluid-image"
import TitleContent from "../../components/title-content/title-content.component"
import Container from "@mui/material/Container"
import { getSrc } from "gatsby-plugin-image"

const BigNumberSection = ({
  backgroundImage,
  image,
  number,
  description,
  leftAlign,
  backgroundColor,
  overlapImage,
}) => {
  return (
    <S.Wrapper bgColor={backgroundColor ? backgroundColor : ""} overlapImg>
      {backgroundImage ? (
        <S.HeroSection img={backgroundImage}>
          <Container max-width="sm">
            <TitleContent
              title={{ text: number, size: 15 }}
              description={{ text: description, size: 1.5 }}
            />
          </Container>
        </S.HeroSection>
      ) : (
        <S.CustomContainer>
          <S.CustomGrid container spacing={2} alignItems="center">
            <S.NumberContainer
              item
              xs={12}
              sm={6}
              className={leftAlign ? "leftAlign" : ""}
            >
              <TitleContent
                title={{ text: number, size: 15 }}
                description={{ text: description, size: 1.5 }}
                className="darkFont"
              />
            </S.NumberContainer>
            <S.ImageContainer
              overlapImg={overlapImage}
              item
              xs={12}
              sm={6}
              className={leftAlign ? "leftAlign" : ""}
            >
              <FluidImage img={getSrc(image.localFile)} role="presentation" />
            </S.ImageContainer>
          </S.CustomGrid>
        </S.CustomContainer>
      )}
    </S.Wrapper>
  )
}
export default BigNumberSection
