import React, { useEffect, useState } from "react"
import * as S from "./news-month-filter.styles"
import { MenuItem } from "@mui/material"
import CustomLink from "../custom-link/custom-link.component"

const NewsMonthFilter = ({ selectedMonth, news }) => {
  const [filter, setFilter] = useState("")
  const [dateFilters, setDateFilters] = useState([])
  const [newsFiltered, setNewsFiltered] = useState([])

  const handleChange = e => {
    setFilter(e.target.value)
  }

  useEffect(() => {
    let dates = []

    news.forEach(({ node }) => {
      const newDate = new Date(node.date)
      const date = `${newDate.toLocaleString("en-us", {
        month: "long",
      })}, ${newDate.getFullYear()}`

      if (!dates.find(d => d.date === date)) {
        dates.push({
          month: newDate.getMonth(),
          year: newDate.getFullYear(),
          date,
        })
      }
    })

    setDateFilters(dates)
    setFilter(dates[0])
  }, [])

  useEffect(() => {
    const newsFiltered = news.filter(
      ({ node }) =>
        new Date(node.date).getFullYear() === filter.year &&
        new Date(node.date).getMonth() === filter.month
    )

    setNewsFiltered(newsFiltered)
  }, [filter])

  if (!news && !newsFiltered && !dateFilters) return null

  return (
    <S.Wrapper>
      <S.CustomSelect value={filter} onChange={handleChange}>
        {dateFilters &&
          dateFilters?.map((item, index) => (
            <S.CustomMenuItem value={item} key={`date-filter-${index}`}>
              {item.date}
            </S.CustomMenuItem>
          ))}
      </S.CustomSelect>

      <div>
        {newsFiltered.map(({ node }, index) => (
          <S.Link url={`/news${node.uri}`} key={`new-filtered-${index}`}>
            <S.NewContainer>
              <S.Title fontSize={1.25}>{node.title}</S.Title>
            </S.NewContainer>
          </S.Link>
        ))}
      </div>
    </S.Wrapper>
  )
}

export default NewsMonthFilter
