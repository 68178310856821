import styled, { css } from "styled-components"
import Container from "@mui/material/Container"
import FluidBgImg from "../../components/fluid-bg-image/fluid-bg-image"
import { Dialog, IconButton, Modal, Typography } from "@mui/material"
import CustomLink from "../../components/custom-link/custom-link.component"
import FluidImage from "../../components/fluid-image/fluid-image"
import ArrowForward from "../../assets/icons/arrow-left-white.svg"
import ArrowBack from "../../assets/icons/arrow-right-white.svg"
import Grid from "@mui/material/Grid"

const sectionHeight = css`
  height: calc(100vh - ${({ theme }) => theme.navHeight}px);
`
export const CustomArrowForward = styled(ArrowForward)`
  width: 16px;
  height: 29px;
`
export const CustomArrowBack = styled(ArrowBack)`
  width: 16px;
  height: 29px;
`
export const Wrapper = styled.section`
  position: relative;
  background-color: ${({ theme }) => theme.palette.primary.main};

  .slick-dots {
    bottom: 40px;
  }
`

export const SlideWrapper = styled(FluidBgImg)`
  ${sectionHeight};
`

export const VideoBg = styled.video`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const ArrowButton = styled(IconButton)`
  color: white;
  position: absolute;
`

export const Arrows = styled(Container)`
  position: absolute;
  top: calc(50% - 25px);
  left: 0;
  right: 0;
  z-index: 1;
  @media (max-width: 1370px) {
    button.left {
      left: -5px;
    }
    button.right {
      right: -5px;
    }
  }
  @media (min-width: 1410px) {
    button.left {
      left: -70px;
    }
    button.right {
      right: -70px;
    }
  }
`

export const MainText = styled.h1`
  position: relative;
  z-index: 2;
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-weight: bold;
  max-width: 322px;
  line-height: 40px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 4rem;
    line-height: 70px;
    max-width: 590px;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-weight: normal;
    max-width: 300px;
  }
`
export const ModalVideo = styled(Dialog)`
  video {
    max-height: 600px;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    object-fit: contain;
  }
`

export const SmallText = styled(Typography)`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  z-index: 2;
  margin: 1em 0;
  font-weight: bold;
  font-size: 1rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 1.5rem;
    line-height: 32px;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: 150px;
    font-weight: normal;
  }
`
export const TextGrid = styled(Grid)`
  max-width: 560px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 71px;
  }
`
export const ContentWrapper = styled(Container)`
  display: flex;
  color: white;
  flex-direction: column;
  ${sectionHeight};
  align-items: flex-end;
  padding-bottom: 8em;

  &.video {
    align-items: center;
    padding-bottom: 0;
  }
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-left: 4em;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-left: 1.5em;
  }
`

export const BookHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
  margin-bottom: 1em;
`

export const AuthorName = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  font-weight: bold;
`

export const AuthorLink = styled(CustomLink)`
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  opacity: 0.5;
  cursor: pointer;
  .MuiSvgIcon-root {
    width: 0.5em;
    height: 0.5em;
    margin-left: 0.4em;
  }
`

export const BookContentWrapper = styled.div`
  display: flex;
`

export const BookCover = styled(FluidImage)`
  width: 105px;
  height: auto;
  box-shadow: 16px 25px 30px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  margin-right: 1em;
`

export const MediaWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 16px 25px 30px 0 rgba(0, 0, 0, 0.19);
  color: white;
  flex-grow: 1;
`

export const VideoWrapper = styled.div`
  //min-height: 600px;
  //height: 100vh;
  //color: white;
  //cursor: pointer;
  ${sectionHeight};
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.palette.primary.main};
  :after {
    position: absolute;
    display: block;
    content: "";
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    pointer-events: none;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    :after {
      background: linear-gradient(
          180deg,
          rgba(15, 20, 24, 0) 36.62%,
          #0f1418 71.29%
        ),
        url("www.radiusbookgroup.com_radius-short-authorstills.png");
    }
  }
`
export const InnerWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: unset;
    .MuiGrid-item:first-child {
      order: 2;
    }
    .MuiGrid-item:last-child {
      order: 1;
    }
  }
`
export const Circle = styled.div`
  cursor: pointer;
  z-index: 2;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.25);
  svg {
    width: 40px;
    height: auto;
  }
  // ${({ theme }) => theme.breakpoints.down("sm")} {
  //   width: 50px;
  //   height: 50px;
  //   svg {
  //     width: 30px;
  //   }
  // }

  :hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 50px;
    margin-bottom: 1em;
  }
`
