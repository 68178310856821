import styled from "styled-components"
import { Typography } from "@mui/material"
import React from "react"
import Grid from "@mui/material/Grid"

export const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary.light};
`
export const AuthorGrid = styled(Grid)`
  justify-content: center;
`
export const Item = styled(Grid)`
  display: flex;
  justify-content: center;
`

export const Title = styled(Typography)`
  padding-left: 7px;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 20px;
  padding-bottom: 4em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    text-align: center;
    padding-top: 1em;
  }
`
