import styled, { css } from "styled-components"
import Container from "@mui/material/Container"
import FluidImage from "../../components/fluid-image/fluid-image"
import CustomTypography from "../../components/custom-typography/custom-typography.component"
import CustomSlider from "../../components/custom-slider/custom-slider.component"

const itemStyles = css`
  padding: 0 1em;
  height: 100px;
  display: flex !important;
`
export const PartnerTitle = styled(CustomTypography)`
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: bold;
  opacity: 0.5;
  padding-bottom: 1.5em;
`
export const PartnersImg = styled(FluidImage)`
  max-height: 100px;
  width: auto;
  margin: auto;
`
export const PartnersContainer = styled(Container)`
  padding-top: 2em;
`
export const PartnersSlider = styled(CustomSlider)`
  .slick-track {
    ${({ theme }) => theme.breakpoints.up("md")} {
      display: flex;
      align-items: center;
    }

    .slick-slide {
      background: red;
    }
  }
`
export const ItemWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down(769)} {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  ${itemStyles};
`
export const ImageLink = styled.a`
  ${({ theme }) => theme.breakpoints.down(768)} {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  ${itemStyles};
`
