import React from "react"
import { idOdd } from "../../utils"
import { graphql, useStaticQuery } from "gatsby"
import * as S from "../FeaturedSuccessSlider/featured-success-slider.styles"
import FeaturedSuccessStory from "../../components/featured-success-story/featured-success-story.component"

const AllSuccessStories = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      author: file(relativePath: { eq: "author-placeholder.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2080) {
            base64
            aspectRatio
            src
            srcSet
            sizes
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allWpSuccessStory(sort: { order: ASC, fields: date }) {
        edges {
          node {
            content
            uri
            title
            successStoryFields {
              author {
                ... on WpAuthor {
                  id
                  featuredImage {
                    node {
                      altText
                      sourceUrl
                      title
                      localFile {
                        childImageSharp {
                          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                        }
                      }
                    }
                  }
                  title
                  authorFields {
                    heroTagName
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const successStories = staticQuery.allWpSuccessStory.edges
  if (!successStories) return null

  return successStories.map((successStory, index) => (
    <S.Wrapper className={idOdd(index) ? "light" : "dark"} contained>
      <FeaturedSuccessStory
        img={
          successStory.node.successStoryFields.author.featuredImage &&
          successStory.node.successStoryFields.author.featuredImage.node
        }
        authorName={successStory.node.successStoryFields.author.title}
        flipCols={idOdd(index) ? true : ""}
        button={{
          uri: successStory.node.uri,
          text: "Read More",
          className: idOdd(index) ? "darkBorder" : "lightBorder",
        }}
        contentText={successStory.node.content}
        tag={
          successStory.node.successStoryFields.author.authorFields.heroTagName
        }
        className={idOdd(index) ? "light" : "dark"}
      />
    </S.Wrapper>
  ))
}
export default AllSuccessStories
