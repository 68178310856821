import styled from "styled-components"
import Container from "@mui/material/Container"
import Slider from "react-slick"
import CustomTypography from "../../components/custom-typography/custom-typography.component"
import React from "react"
import FluidImage from "../../components/fluid-image/fluid-image"
import Grid from "@mui/material/Grid"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import Quotes from "../../assets/icons/quotes-author.svg"

export const Wrapper = styled(SectionWrapper)`
  background-color: white;
  position: relative;
  padding-bottom: 0;
  padding-top: 0;
  .slick-dots {
    position: relative;
    ${({ theme }) => theme.breakpoints.up("sm")} {
      bottom: 25px;
    }
    @media (min-width: 738px) and (max-width: 900px) {
      margin-top: 3.3em;
    }
    li {
      button {
        border: 1px solid gray;
      }
      &.slick-active {
        button {
          background-color: ${({ theme }) => theme.palette.secondary.main};
          border: 1px solid ${({ theme }) => theme.palette.secondary.main};
        }
      }
    }
  }
`
export const ContentText = styled(CustomTypography)`
  opacity: 0.5;
`
export const Title = styled(CustomTypography)`
  padding-bottom: 2em;
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-weight: bold;
`
export const FirstSlider = styled(Slider)`
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-bottom: 2em;
  }

  .slick-slide {
    pointer-events: none;
    &.slick-active {
      pointer-events: auto;
      cursor: pointer;
    }
  }
`
export const CustomSlider = styled(Slider)`
  max-height: 63px;
  max-width: 366px;
  margin: 0;
  svg {
    color: black;
    opacity: 0.4;
    width: 30px;
    height: 30px;
    :hover {
      color: black;
      opacity: 1;
    }
  }
  .slick-list {
    padding: 1em 0 !important;
  }

  .slick-current .MuiGrid-root img {
    //width: 50px;
    //height: 50px;
    transform: scale(1.5);
    border: ${({ theme }) => theme.palette.text.tertiary} 1px solid;
  }
`
export const AbsoluteContainer = styled(Container)`
  position: absolute;
  bottom: 3rem;
  left: 4rem;
  display: none;

  ${({ theme }) => theme.breakpoints.up("md")} {
    bottom: 2rem;
  }

  ${({ theme }) => theme.breakpoints.up(960)} {
    display: block;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    bottom: 4rem;
  }
`
export const SliderGrid = styled(Grid)``
export const GridItem = styled(Grid)`
  display: flex;
`

export const AuthorImageContainer = styled.div`
  margin: 0 auto;
  width: 37px;
  height: 37px;
  overflow: hidden;
  border-radius: 50%;
`

export const AuthorImage = styled(FluidImage)`
  margin: 0 auto;
  width: 37px;
  height: 37px;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.3s ease;
`

export const QuotesIcon = styled(Quotes)`
  margin-bottom: 1.25rem;
  width: 47px;
  height: 33px;
`
export const CustomContainer = styled(Container)`
  display: block;
  padding-top: 3em;
`
export const Content = styled(Grid)``
export const Text = styled(CustomTypography)`
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  padding-bottom: 1rem;
  font-weight: bold;
  text-align: left;
  font-size: ${({ theme }) => theme.typography.pxToRem(40)};
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 400px;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-bottom: 2rem;
    font-size: ${({ theme }) => theme.typography.pxToRem(50)};
  }
`
export const QuoteText = styled(CustomTypography)`
  padding-bottom: 1em;
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  opacity: 0.5;
  font-weight: 400;
  text-align: justify;
  max-width: 500px;
  width: 100%;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)} !important;

  @media (max-width: 1185px) {
    font-size: 1.5rem;
    padding-bottom: 1em;
  }
  @media (min-width: 601px) and (max-width: 1050px) {
    font-size: 1.2rem;
    padding-bottom: 1em;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(22)};
    margin-bottom: 3.5rem;
  }
`
export const AuthorName = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 3.33px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: left;
  color: ${({ theme }) => theme.palette.text.tertiary};
`
export const Description = styled(CustomTypography)`
  color: ${({ theme }) => theme.palette.text.primary};
  opacity: 0.5;
  margin-bottom: 1em;
  text-align: left;
  span {
    font-style: italic;
  }
`
