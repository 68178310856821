import styled from "styled-components"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import CustomTypography from "../../components/custom-typography/custom-typography.component"

export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 3em 0;
  }
  background-color: ${({ theme }) => theme.palette.secondary.main};
`
export const ImageContainer = styled(Grid)`
  order: 1;
  .img {
    height: 100%;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 2em;
    order: 2;
  }
`
export const ItemGrid = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  order: 2;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    .items-container {
      padding-top: 1em;
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 1;
  }
`
export const CustomContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 4em 6em;
`
export const Title = styled(CustomTypography)`
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-weight: bold;
  padding-bottom: 0.3em;
  color: ${({ theme }) => theme.palette.text.secondary};
  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 2rem;
  }
`
export const Item = styled.div`
  padding-bottom: 1.5em;
`
