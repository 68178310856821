import styled from "styled-components"
import { Typography } from "@mui/material" 
import FluidImage from "../fluid-image/fluid-image"

export const CardContainer = styled.div`
  background-color: transparent;
  max-width: 285px;
  height: auto;
`

export const AuthorContainer = styled.div`
  max-width: 263px;
  text-overflow: clip;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-bottom: 3em;
    p {
      text-align: center;
    }
  }
`

export const AuthorImageContainer = styled.div`
  max-width: 181px;
  padding-bottom: 2em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: 0 auto;
  }
`

export const AuthorImage = styled(FluidImage)`
  height: 100%;
  width: auto;
`

export const AuthorName = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 20px;
  padding-bottom: 0.5em;
`

export const Role = styled(Typography)`
  opacity: 0.5;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  letter-spacing: 1px;
  line-height: 19px;
  padding-bottom: 2em;
`

export const Description = styled(Typography)`
  opacity: 0.5;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  letter-spacing: 0.88px;
  line-height: 29px;
`
