import React from "react"

import * as S from "./author-card.styles"
import { navigate } from "gatsby"

const AuthorCard = ({ authorImage, authorName, role, slug, homeImage }) => {
  return (
    <S.CardContainer url={slug}>
      <S.AuthorImage img={homeImage ? homeImage : authorImage} />
      <S.AuthorContainer>
        <S.AuthorName>{authorName}</S.AuthorName>
        <S.Role>{role}</S.Role>
      </S.AuthorContainer>
    </S.CardContainer>
  )
}

export default AuthorCard
