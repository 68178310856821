import React from "react"
import * as S from "./news-card.styles"
import Grid from "@mui/material/Grid"
import { removeHTML, textEllipsis } from "../../utils"
import moment from "moment"
import { CustomLink } from "../custom-link/custom-link.styles"

const NewsCard = ({ image, title, description, link, publicationDate }) => {
  const parse = require("html-react-parser")
  const formatDate = publicationDate
    ? moment(publicationDate, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY")
    : ""
  return (
    <S.Card>
      {publicationDate && <S.Date>{formatDate}</S.Date>}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          {image && (
            <S.ImageLink url={link}>
              <S.CustomImage img={image} />
            </S.ImageLink>
          )}
        </Grid>
        <Grid item xs={12} sm={9}>
          <S.Content>
            {title && <S.Title>{title}</S.Title>}
            {description && <S.Description>{parse(description)}</S.Description>}
            {link && <S.Link url={link}>Read More</S.Link>}
          </S.Content>
        </Grid>
      </Grid>
    </S.Card>
  )
}

export default NewsCard
