import styled from "styled-components"
import { MenuItem, Select } from "@mui/material"
import CustomTypography from "../custom-typography/custom-typography.component"
import CustomLink from "../custom-link/custom-link.component"

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 1.5rem;
`

export const CustomSelect = styled(Select)`
  width: 100%;
  text-transform: capitalize;
`

export const CustomMenuItem = styled(MenuItem)`
  text-transform: capitalize;
`
export const Link = styled(CustomLink)`
  width: 100%;
  border-bottom: 1px solid rgba(62, 62, 0, 0.2);
`
export const NewContainer = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.063rem;
`

export const Title = styled(CustomTypography)`
  font-weight: 600;
  line-height: 140%;
  font-family: ${({ theme }) => theme.fonts.primaryFont};
`
