import styled from "styled-components"
import FluidBgImg from "../../components/fluid-bg-image/fluid-bg-image"
import Container from "@mui/material/Container"
import { Typography } from "@mui/material"

export const Wrapper = styled.section`
  position: relative;
  background-color: ${({ theme }) => theme.palette.primary.light};
  p,
  h1,
  h2,
  h3 {
    position: relative;
    z-index: 2;
  }
`

export const HeroSection = styled(FluidBgImg)`
  min-height: 500px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-height: 300px;
  }
  background-position: top !important;

  div {
    position: absolute;
    display: block;
    right: 0;
    left: 0;
    bottom: 0;
    height: 321px;
    width: 100%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(15, 20, 24, 0) 0%, #0f1418 100%);
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(60)};
  font-weight: bold;
  letter-spacing: 3.75px;
  line-height: 69px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    letter-spacing: 3.19px;
    line-height: 57px;
    font-size: ${({ theme }) => theme.typography.pxToRem(51)};
  }
`
export const TitleContainer = styled.div`
  max-width: 240px;
`
export const CustomContainer = styled(Container)`
  border-bottom: rgb(216, 216, 216, 0.2) 1px solid;
  padding-bottom: 6em;
  position: relative;
  z-index: 2;

  ${({ theme }) => theme.breakpoints.up("md")} {
    position: relative;
    margin-top: -4em;
  }
`
export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: 29px;
`
