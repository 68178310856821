import React from "react"
import * as S from "./radius-authors.styles.jsx"
import FluidImage from "../../components/fluid-image/fluid-image"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import { Check } from "@mui/icons-material"

const RadiusAuthors = ({ items, leftImage, title, book }) => {
  return (
    <S.Wrapper contained>
      <Grid container spacing={5}>
        <S.GridContainer item xs={12} sm={6}>
          <S.ImageContainer tag="div" img={leftImage}>
            <Grid container>
              <S.BookImage item xs>
                <FluidImage img={book.featuredImage.node} />
              </S.BookImage>
              <S.BookContent item xs>
                <S.Mask />
                <h2>{book.title}</h2>
                <p className="subtitle">
                  {book.booksFields.heroSubtitleExcerpt}
                </p>
                <p>by {book.booksFields.authorRepeater[0].author.title}</p>
              </S.BookContent>
            </Grid>
          </S.ImageContainer>
          {/*<S.ImageContainer>*/}
          {/*  <FluidImage img={leftImage} />*/}
          {/* */}
          {/*</S.ImageContainer>*/}
        </S.GridContainer>
        <Grid item xs={12} sm={6}>
          <S.ItemContainer maxWidth="sm">
            <S.Title ls={4.5}>Radius Authors</S.Title>
            <List>
              {items.map(item => (
                <ListItem>
                  <S.CustomCheck>
                    <Check />
                  </S.CustomCheck>
                  <S.ContentText primary={item.item} />
                </ListItem>
              ))}
            </List>
          </S.ItemContainer>
        </Grid>
      </Grid>
    </S.Wrapper>
  )
}
export default RadiusAuthors
