import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  position: relative;
  background-color: ${({ theme }) => theme.palette.tertiary.main};

  ::after {
    content: "";
    display: block;
    position: absolute;
    background-color: #f2f0ec;
    height: 50%;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  video {
    width: 100%;
    position: relative;
    z-index: 3;
    max-width: 977px;
    max-height: 550px;
    margin: auto;
    display: block;
  }
`
export const Title = styled.h1`
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 120%;
  padding-bottom: 1.5em;
  max-width: 677px;
  margin: auto;
`
