import React from "react"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import Grid from "@mui/material/Grid"
import AuthorCard from "../../components/author-card/author-card.component"
import { graphql, useStaticQuery } from "gatsby"
const AllAuthors = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      author: file(relativePath: { eq: "author2-placeholder.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2880) {
            base64
            aspectRatio
            src
            srcSet
            sizes
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allWpBook {
        edges {
          node {
            booksFields {
              publicationDate
              authorRepeater {
                author {
                  ... on WpAuthor {
                    title
                    slug
                    uri
                    authorFields {
                      heroTagName
                    }
                    featuredImage {
                      node {
                        altText
                        sourceUrl
                        title
                        localFile {
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const books = staticQuery.allWpBook.edges
  const orderedBooks = books.sort(function (a, b) {
    return (
      new Date(b.node?.booksFields?.publicationDate) -
      new Date(a.node?.booksFields?.publicationDate)
    )
  })
  function removeRepeatedAuthors(arr) {
    return [...new Map(arr.map(item => [item.author.title, item])).values()]
  }
  const authors = removeRepeatedAuthors(
    orderedBooks
      .map(item => {
        return item.node?.booksFields?.authorRepeater
      })
      .flat()
  )
  if (!authors) return null
  return (
    <div>
      <SectionWrapper contained>
        <Grid container>
          {authors.map(item => (
            <Grid item xs={6} sm={4} md={3}>
              <AuthorCard
                authorImage={
                  item.author.featuredImage.node ?? staticQuery.author
                }
                authorName={item.author.title}
                role={item.author.authorFields.heroTagName}
                // slug={item.author.uri}
              />
            </Grid>
          ))}
        </Grid>
      </SectionWrapper>
    </div>
  )
}
export default AllAuthors
